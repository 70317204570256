<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveItemHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-row class="ml-0 mt-10 mb-0" no-gutters>
        <v-col cols="12">
          <v-text-field
              color="deep-purple accent-4"
              class="right-align-text"
              v-model="currentItem.sheet"
              :rules="requiredFieldRule"
              label="Sheet Name"
              prepend-icon="mdi-book-open"
              required
              dense
              outlined
          />
          <v-textarea
              color="deep-purple accent-4"
              class="right-align-text"
              v-model="currentItem.updateColumn"
              :rules="requiredFieldRule"
              label="Ahrefs Column"
              prepend-icon="mdi-comment"
              required
              dense
              outlined
              rows="2"
          />
          <v-textarea
              color="deep-purple accent-4"
              class="right-align-text"
              v-model="currentItem.sheetColumn"
              :rules="requiredFieldRule"
              label="Ahrefs Sheet Column"
              prepend-icon="mdi-comment"
              required
              dense
              outlined
              rows="2"
          />
          <v-textarea
              color="deep-purple accent-4"
              class="right-align-text"
              v-model="currentItem.mozUpdateColumn"
              :rules="requiredFieldRule"
              label="MOZ Column"
              prepend-icon="mdi-comment"
              required
              dense
              outlined
              rows="2"
          />
          <v-textarea
              color="deep-purple accent-4"
              class="right-align-text"
              v-model="currentItem.mozSheetColumn"
              :rules="requiredFieldRule"
              label="MOZ Sheet Column"
              prepend-icon="mdi-comment"
              required
              dense
              outlined
              rows="2"
          />
          <v-text-field
              color="deep-purple accent-4"
              class="right-align-text"
              v-model="currentItem.fieldOne"
              :rules="priceRule"
              type="number"
              label="First Row ID (title row number)"
              prepend-icon="mdi-page-first"
              required
              dense
              outlined
          />
          <v-text-field
              color="deep-purple accent-4 site-field"
              class="right-align-text"
              v-model="currentItem.fieldTwo"
              :rules="priceRule"
              label="Last Row ID (subtraction with first row num)"
              prepend-icon="mdi-page-last"
              type="number"
              required
              dense
              outlined
          />
        </v-col>
      </v-row>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";

export default {
  name: 'GoogleSheetSyncPopup',
  components: {BasePopup},
  mixins: [PopupMixin],
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Edit Google Sheet Row Item'
          : 'Add Google Sheet Row Item'
    }
  }
}
</script>
